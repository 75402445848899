import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => (
    <Layout>
        <SEO title="Posts" />
        <h1>Testing the gatsby-transformer-remark</h1>
        <p>These posts get read from a markdown file</p>
        <table>
            <thead><tr>
                <th>Post Title</th>
                <th>Post Author</th>
                <th>Excerpt</th>
            </tr></thead>
            <tbody>
                {data.allMarkdownRemark.edges.map(({ node }) =>
                    <tr>
                        <td>{node.frontmatter.title}</td>
                        <td>{node.frontmatter.author}</td>
                        <td>{node.excerpt}</td>
                    </tr>
                )}
            </tbody>
        </table>
        <Link to="/">Go to home page</Link>
    </Layout>
)

export const query = graphql`
    query PostQuery {
        allMarkdownRemark {
            edges {
                node {
                    id
                    excerpt
                    fileAbsolutePath
                    frontmatter {
                        title
                        author
                    }
                }
            }
        }
    }
`